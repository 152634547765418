import { AxiosRequestConfig, RawAxiosRequestHeaders, AxiosResponse, AxiosError } from 'axios';
import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { Decimal } from 'decimal.js';
import * as Sentry from '@sentry/nextjs';
import Resizer from 'react-image-file-resizer';
import formatter from 'format-number';

import _ from 'common/lodash';
import { IRequestObject, IRequestError, IUser } from 'common/types';
import environment from 'core/config';
import { initilizeHttpClient } from 'core/axios-config';
import constants from './constants';
import { IUserAddress, IUserAddressSave } from 'api/user/types';

export type { AxiosResponse, AxiosRequestConfig, RawAxiosRequestHeaders };

export interface IAxiosRequestConfig extends AxiosRequestConfig {
    isExternalRequest?: boolean;
}

export const formatImageCountDisplay = (currentItem: number, total: number) => {
    return `${currentItem} od ${total}`;
};

export const formAxiosObject = async (
    requestObject: IRequestObject,
    data?: object | null,
    params?: object | null,
    urlParams?: object | null,
    headers?: RawAxiosRequestHeaders | null,
    isExternalRequest?: boolean,
    config?: object | null,
): Promise<any> => {
    try {
        if (!requestObject || !requestObject.route || !requestObject.method) {
            return null;
        }

        let { route } = requestObject;

        if (urlParams && !_.isObjectEmpty(urlParams)) {
            Object.keys(urlParams).forEach((key) => {
                route += `/${urlParams[key as keyof typeof urlParams]}`;
            });
        }

        const axiosObject: IAxiosRequestConfig = {
            url: route,
            method: requestObject.method,
            isExternalRequest,
        };

        const { method } = axiosObject;

        if (data && (method === 'POST' || method === 'PUT' || method === 'DELETE' || method === 'PATCH')) {
            axiosObject.data = data;
        }

        if (params) {
            axiosObject.params = params;
        }

        if (headers) {
            axiosObject.headers = headers;
        }

        axiosObject.timeout = 5000;

        const API = await initilizeHttpClient(environment.API_URL);
        const response = await API({ ...axiosObject, ...(config || {}) });

        return response;
    } catch (error) {
        if (environment.SENTRY) {
            Sentry.captureException(error);
        }

        console.log('err ', error);

        throw {
            response: (error as AxiosError)?.response,
            error: new Error(),
        };
    }
};

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export const generateRandomString = (length: number) => {
    let result = '';
    const possible = 'abcdefghijklmnopqrstuvwxyz0123456789-_';

    for (let i = 0; i < length; i += 1) {
        result += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return result;
};

export const getAPIErrorCode = (error: IRequestError) => {
    return error?.response?.data?.error?.code;
};

export const parseJSON = async (strignified: string) => {
    try {
        const result = JSON.parse(strignified);

        return result;
    } catch (error) {
        return null;
    }
};

export const getRandomElementFromArray = <T>(arr: T[]) => {
    if (Array.isArray(arr) && arr.length > 0) {
        const randomIndex = Math.floor(Math.random() * arr.length);
        return arr[randomIndex];
    } else {
        return null;
    }
};

export const replaceLastPeriodWithComma = (inputString: string) => {
    const lastPeriodIndex = inputString.lastIndexOf('.');

    if (lastPeriodIndex !== -1) {
        const beforeLastPeriod = inputString.substring(0, lastPeriodIndex);
        const afterLastPeriod = inputString.substring(lastPeriodIndex + 1);

        const formattedString = beforeLastPeriod + ',' + afterLastPeriod;
        return formattedString;
    }

    return inputString;
};
export const multiplyTwoNumbers = (number: number, multiplier: number): number => {
    return new Decimal(number).times(multiplier).toNumber();
};

export const divideTwoNumbers = (number: number, divider: number, showWithDecimals?: boolean, decimals?: number) => {
    return showWithDecimals
        ? // @ts-ignore
          formatter({ integerSeparator: '.', decimalsSeparator: ',', decimal: ',' })(parseFloat(new Decimal(number).dividedBy(divider).toNumber().toString()).toFixed(decimals || 2))
        : new Decimal(number).dividedBy(divider).toNumber();
};

export const hexToRgb = (hex: string, defaultColor: { r: number; g: number; b: number }) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : defaultColor;
};

export const shortenText = (text: string, maxChars: number) => {
    if (text.length > maxChars) {
        return `${text.substring(0, maxChars)}...`;
    }

    return text;
};

export const formQueryParams = (params: object) => {
    let result = '';

    if (params && !_.isObjectEmpty(params)) {
        const keys = Object.keys(params).filter((key: any) => params[key as keyof typeof params]);

        keys.forEach((key, index) => {
            result += `${index === 0 ? '?' : '&'}${key}=${params[key as keyof typeof params]}`;
        });
    }

    return result;
};

export const parseBoolean = (value: any) => {
    if (typeof value === 'boolean') {
        return value;
    } else if (typeof value === 'string') {
        const lowercasedValue = value.toLowerCase();
        if (lowercasedValue === 'true' || lowercasedValue === '1') {
            return true;
        } else if (lowercasedValue === 'false' || lowercasedValue === '0') {
            return false;
        }
    }

    return null;
};

export const isNonImageURL = (text: string) => {
    const urlPattern = new RegExp(
        // Protocol part
        '(https?://)?' +
            // Domain part
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))' +
            // Optional port
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
            // Optional query string
            '(\\?[;&a-z\\d%_.~+=-]*)?' +
            // Optional fragment
            '(\\#[-a-z\\d_]*)?$',
        'i',
    );

    const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|svg|webp)$/i;

    // Check if the text matches the URL pattern
    if (!urlPattern.test(text)) {
        return false;
    }

    // Check if the URL doesn't end with an image file extension
    return !imageExtensions.test(text);
};

export const isImage = (text: string) => {
    const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|svg|webp)$/i;

    return imageExtensions.test(text);
};

export const resizeFile = (file: File | undefined, quality = 80): Promise<File> | null => {
    try {
        if (file) {
            return new Promise((resolve) => {
                Resizer.imageFileResizer(
                    file,
                    2048,
                    2048,
                    'JPEG',
                    quality,
                    0,
                    (uri) => {
                        // @ts-ignore
                        resolve(uri);
                    },
                    'file',
                );
            });
        }

        return null;
    } catch (error) {
        if (file) {
            return new Promise((resolve) => {
                resolve(file);
            });
        }

        return null;
    }
};

export const resizeImage = async (file: File, maxSize: number, quality = 80): Promise<any> => {
    const resizedImage = await resizeFile(file);

    if ((resizedImage && resizedImage.size <= maxSize) || quality <= 0) {
        return resizedImage;
    }
    return await resizeImage(file, maxSize, quality - 10);
};

export const getFieldErrorNames = (formikErrors: { [key: string]: string }) => {
    const transformObjectToDotNotation = (obj: { [key: string]: string }, prefix = '', result: string[] = []) => {
        Object.keys(obj).forEach((key) => {
            const value = obj[key];
            if (!value) return;

            const nextKey = prefix ? `${prefix}.${key}` : key;
            if (typeof value === 'object') {
                transformObjectToDotNotation(value, nextKey, result);
            } else {
                result.push(nextKey);
            }
        });

        return result;
    };

    return transformObjectToDotNotation(formikErrors);
};

export const getCurrencyCode = (currencyValue: string): string => {
    for (const currency in constants.CURRENCIES) {
        if (constants.CURRENCIES.hasOwnProperty(currency)) {
            if (constants.CURRENCIES[currency as keyof typeof constants.CURRENCIES].value === currencyValue) {
                return constants.CURRENCIES[currency as keyof typeof constants.CURRENCIES].code;
            }
        }
    }
    return currencyValue;
};

export const userVerified = (userDetails?: IUser) => {
    if (userDetails?.verificationStatus === constants.USER_STATUSES.verified.value) {
        return true;
    }

    if (userDetails?.firstName && userDetails?.lastName && userDetails?.email && userDetails?.addressVerified) {
        return true;
    }

    return false;
};

export const calculateDays = (durationDays: number | undefined, durationWeeks: number | undefined): number => {
    const days = durationDays ?? 0;
    const weeks = durationWeeks ?? 0;

    return weeks * 7 + days;
};

export const convertToEmbeddedUrl = (url: string) => {
    const youTubeUrl = url.trim();

    if (youTubeUrl.includes('youtube.com/embed/')) {
        return youTubeUrl;
    } else if (youTubeUrl.includes('youtube.com/watch?v=')) {
        return youTubeUrl.replace('watch?v=', 'embed/').trim();
    } else if (youTubeUrl.includes('youtu.be/')) {
        const videoId = youTubeUrl.split('youtu.be/')[1].split('?')[0];
        return `https://www.youtube.com/embed/${videoId}`;
    } else {
        throw new Error('Unsupported YouTube URL format');
    }
};

export const getVideoIdFromUrl = (url: string) => {
    // eslint-disable-next-line
    const regex = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);

    if (match && match[1]) {
        return match[1];
    } else {
        return null;
    }
};

export const deepCopy = (obj: any): any => {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    if (Array.isArray(obj)) {
        const copy = [];
        for (let i = 0; i < obj.length; i++) {
            copy[i] = deepCopy(obj[i]);
        }
        return copy;
    }

    const copy = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            // @ts-ignore
            copy[key] = deepCopy(obj[key]);
        }
    }
    return copy;
};

export const extractInstagramUsername = (url: string) => {
    const regex = /^(?:https?:\/\/)?(?:www\.)?instagram\.com\/([a-zA-Z0-9_.]+)(?:\/)?$/;

    const match = url.match(regex);

    if (match && match[1]) {
        return match[1];
    } else {
        return url;
    }
};

export const extractFacebookUsername = (url: string) => {
    const regex = /^(?:https?:\/\/)?(?:www\.)?facebook\.com\/(?:profile\.php\?id=)?([a-zA-Z0-9_.-]+)(?:\/)?$/;

    const match = url.match(regex);

    if (match && match[1]) {
        return match[1];
    } else {
        return url;
    }
};

export const areAddressesEqual = (address1: IUserAddress, address2: IUserAddressSave) => {
    if (
        address1.address !== address2.address ||
        address1.area !== address2.area ||
        address1.city !== address2.city ||
        address1.postCode !== address2.postCode ||
        address1.state !== address2.state ||
        address1.verifyImage !== address2.verifyImage
    ) {
        return false;
    }
    return true;
};
