import { IRoute } from 'common/types';

interface IRoutes {
    [key: string]: IRoute;
}

export const prefixes = {
    home: 'home',
    search: 'search',
    login: 'login',
    registration: 'registration',
    confirmPhone: 'confirm-phone',
    forgotPassword: 'forgot-password',
    createProduct: 'create-product',
    product: 'product',
    messages: 'messages',
    user: 'user',
    kyc: 'kyc',
    settings: 'settings',
    verify: 'verify',
    confirmEmail: 'confirm-email',
    orders: 'orders',
    orderDetails: 'order-details',
    banners: 'banners',
    categories: 'categories',
    about: 'about',
    tos: 'tos',
    wallet: 'wallet',
    subscription: 'subscription',
    terms: 'terms',
    healthz: 'healthz',
    support: 'support',
};

export const routes: IRoutes = {
    [prefixes.home]: {
        path: '/',
        is_protected: false,
        show_header: true,
        prefix: prefixes.home,
        show_footer: true,
    },
    [prefixes.search]: {
        path: '/search',
        is_protected: false,
        show_header: true,
        prefix: prefixes.search,
    },
    [prefixes.categories]: {
        path: '/categories',
        is_protected: false,
        show_header: true,
        prefix: prefixes.categories,
    },
    [prefixes.product]: {
        path: '/product-details',
        is_protected: false,
        show_header: true,
        prefix: prefixes.product,
        show_footer: true,
    },
    [prefixes.user]: {
        path: '/user',
        is_protected: false,
        show_header: true,
        prefix: prefixes.user,
        prevent_scroll_top: true,
    },
    [prefixes.login]: {
        path: '/login',
        is_protected: false,
        hide_header: true,
        prefix: prefixes.login,
    },
    [prefixes.registration]: {
        path: '/registration',
        is_protected: false,
        hide_header: true,
        prefix: prefixes.registration,
    },
    [prefixes.forgotPassword]: {
        path: '/forgot-password',
        is_protected: false,
        hide_header: true,
        prefix: prefixes.forgotPassword,
    },
    [prefixes.confirmPhone]: {
        path: '/confirm-phone',
        is_protected: true,
        hide_header: true,
        prefix: prefixes.confirmPhone,
    },
    [prefixes.createProduct]: {
        path: '/create-product',
        is_protected: true,
        prefix: prefixes.createProduct,
        hide_search_bar: true,
    },
    [prefixes.messages]: {
        path: '/messages',
        is_protected: true,
        prefix: prefixes.messages,
    },
    [prefixes.kyc]: {
        path: '/kyc',
        is_protected: true,
        prefix: prefixes.kyc,
    },
    [prefixes.settings]: {
        path: '/settings',
        is_protected: true,
        prefix: prefixes.settings,
        show_footer: true,
    },
    [prefixes.verify]: {
        path: '/verify',
        is_protected: true,
        prefix: prefixes.verify,
    },
    [prefixes.confirmEmail]: {
        path: '/confirm-email',
        is_protected: false,
        hide_header: true,
        prefix: prefixes.confirmEmail,
    },
    [prefixes.orders]: {
        path: '/orders',
        is_protected: true,
        prefix: prefixes.orders,
        hide_search_bar: true,
    },
    [prefixes.orderDetails]: {
        path: '/order-details',
        is_protected: true,
        prefix: prefixes.orderDetails,
    },
    [prefixes.banners]: {
        path: '/banners',
        is_protected: true,
        prefix: prefixes.banners,
    },
    [prefixes.about]: {
        path: '/about',
        is_protected: false,
        prefix: prefixes.about,
        show_header: true,
        show_footer: true,
    },
    [prefixes.tos]: {
        path: '/tos',
        is_protected: false,
        hide_header: true,
        prefix: prefixes.tos,
    },
    [prefixes.wallet]: {
        path: '/wallet',
        is_protected: true,
        prefix: prefixes.wallet,
    },
    [prefixes.subscription]: {
        path: '/subscription',
        is_protected: true,
        prefix: prefixes.subscription,
    },
    [prefixes.terms]: {
        path: '/terms',
        is_protected: false,
        hide_header: true,
        prefix: prefixes.terms,
    },
    [prefixes.healthz]: {
        path: '/healthz',
        is_protected: false,
        hide_header: true,
        prefix: prefixes.healthz,
    },
    [prefixes.support]: {
        path: '/support',
        is_protected: false,
        hide_header: true,
        prefix: prefixes.support,
    },
};
